// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* tooltip.css */
.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    font-size: 10px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/pre-built/common/Tooltip.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* tooltip.css */\n.tooltip-container {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .tooltip-text {\n    visibility: hidden;\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n    border-radius: 4px;\n    font-size: 10px;\n    padding: 5px;\n    position: absolute;\n    z-index: 1;\n    bottom: 125%;\n    left: 50%;\n    transform: translateX(-50%);\n    white-space: nowrap;\n  }\n  \n  .tooltip-container:hover .tooltip-text {\n    visibility: visible;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
