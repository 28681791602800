import React, { useState } from "react";
// import Logo from "../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import chevronRight from "../../../images/chevron-compact-right.svg";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import chestxray from "../../../images/chestxray.png";
import bone from "../../../images/MSK.png";

const HomePage = () => {
  const [checkTerm, setCheckTerm] = useState(false);

  const handleInput = (e) => {
    if (e.target.checked) {
      setCheckTerm(true);
    } else {
      setCheckTerm(false);
    }
  };

  return (
    <>
      <Head title="HomePage" />
      <Content>
        <div className="nk-block-head nk-block-head-sm text-center">
          <h2>Experience Comprehensive AI for Medical Imaging</h2>
        </div>
        <Block>
          <div className="custom-home row g-gs">
            <div className="col-sm-6 col-lg-6 col-xxl-6">
              <div
                className="card-preview h-100 card bg-black"
                style={{ borderRadius: "40px" }}
              >
                <div className="card-inner ">
                  <Link to="/cxr-form">
                    <div className="project">
                      <div className="image-icon-container">
                        <img className="image-3-icon" alt src={chestxray} />
                      </div>
                      <div className="project-name">
                        <h2>Chest X Ray</h2>
                        <img
                          className="image-checron-right"
                          alt="right-icon"
                          src={chevronRight}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-6 col-xxl-6">
              <div
                className="card-preview h-100 card bg-black"
                style={{ borderRadius: "40px" }}
              >
                <div className="card-inner ">
                  <Link to="/bone-form">
                    <div className="project">
                      <div className="image-icon-container">
                        <img className="image-3-icon" alt src={bone} />
                      </div>

                      <div className="project-name">
                        <h2>Bone</h2>
                        <img
                          className="image-checron-right"
                          alt="right-icon"
                          src={chevronRight}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Block>
      </Content>
    </>
  );
};
export default HomePage;
