import React, { useState, useEffect, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Progress,
  DropdownItem,
  Badge,
  Input,
  Dropdown,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { projectData } from "../projects/ProjectData";
import {
  findUpper,
  setDeadline,
  setDeadlineDays,
  calcPercentage,
} from "../../../utils/Utils";
import FormModal from "../projects/FormModal";
import { ReactDataTable } from "../../../components/Component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSliders,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import DownloadIcon from "../../../images/download.png";

import EyeIcon from "../../../images/eye.png";
import SearchIcon from "../../../images/Search.png";
import SettingsIcon from "../../../images/whh_settingsandroid.png";
import {
  Spinner,
  Modal,
  ModalFooter,
  ModalHeader,
  Card,
  Form,
} from "reactstrap";
import { URLS } from "../../../constants/urls";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import LoadingScreen from "../../loader/LoadingScreen";
import ReactPaginate from "react-paginate";
import CustomPagination from "../CustomPagination";
import moment from "moment";
import { useNavigate } from "react-router";
import MultiSelectDropdown from "../common/MultiSelectDropdown";
import { forceDownload } from "../../../utils/Utils";
import Tooltip from "../common/Tooltip";

export const ReportsList = () => {
  const navigate = useNavigate();
  const [sm, updateSm] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const [reportsList, setReportsList] = useState([]);
  const [loading, setLoading] = useState(false);

  let [count, setCount] = useState();
  const [limitvalue, setlimitvalue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchByPatientId, setSearchByPatientId] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [result, setResult] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [findingsData, setFindingsData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const childRef = useRef();

  const handleSelect = (selected) => {
    setSelectedOptions(selected);
  };

  const columns = [
    {
      name: "Update Date",
      selector: (row) => row.created_at,

      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {moment(row.created_at).format("DD MMM YY hh:mm A")}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Patient Info",
      selector: (row) => row.patient_name,
      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card new-patient-user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row.patient_id}</span>
            <a> | </a>
            <span className="tb-lead">{row.patient_name}</span>
            <a> | </a>
            <span className="tb-lead">
              {row.age}{" "}
              {row.gender == 1
                ? "M"
                : row.gender == 2
                ? "F"
                : row.gender == 3
                ? "Other"
                : ""}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Report Type",
      selector: (row) => row.patient_name,
      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card new-patient-user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.body_part == 1
                ? "Hand"
                : row.body_part == 2
                ? "Spine"
                : row.body_part == 3
                ? "Default"
                : "CXR"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Result",
      selector: (row) => row.is_diagnosed,
      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div
          className="user-card result-btn mt-2 mb-2"
          style={{
            background: `${row.is_diagnosed == 1 ? "#DC3545" : "#28A745"}`,
          }}
        >
          <div className="user-info">
            <span className="tb-lead">
              {row.is_diagnosed == 1 ? "Positive" : "Negative"}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Download",
      selector: (row) => row.id,
      cell: (row) => (
        <div className="user-card small-btn mt-2 mb-2">
          <Tooltip text="Download">
            {/* <Link to={`/editCustomer-page/${row.id}`}> */}{" "}
            <button
              className="dropdown-item btn btn-md"
              onClick={() => {
                downloadUrl(row?.download_url);
              }}
            >
              <img src={DownloadIcon} width={26} height={26} />
              {/* <em class="icon ni ni-edit"></em> */}
            </button>
            {/* </Link> */}
          </Tooltip>
        </div>
      ),
    },
    {
      name: "View",
      selector: (row) => row.cxr_id,
      cell: (row) => (
        <div className="user-card small-btn mt-2 mb-2">
          <Tooltip text="View">
            {/* <Link to={`/editCustomer-page/${row.id}`}> */}{" "}
            <button
              className="dropdown-item btn btn-md"
              onClick={() => {
                redirectToEditReport(row.cxr_id);
              }}
            >
              <img src={EyeIcon} width={26} height={26} />
              {/* <em class="icon ni ni-edit"></em> */}
            </button>
            {/* </Link> */}
          </Tooltip>
        </div>
      ),
    },
  ];

  const redirectToEditReport = (cxrid) => {
    navigate("/edit-report/" + cxrid);
  };

  const customStyles = {
    rows: {
      style: {
        backgroundColor: "#08223C", // Change background color
      },
    },
  };

  const getReportsList = () => {
    if (currentPage == 1 && !searchByPatientId) {
      setLoading(true);
    }
    let data = {};
    let findingsIds = [];

    if (selectedOptions?.length > 0) {
      selectedOptions?.forEach((val) => {
        findingsIds.push(val.label);
      });
    }

    // console.log(findingsIds, "finding ids");

    data.per_page = limitvalue ? limitvalue : 10;
    data.from_date = startDate;
    data.to_date = endDate;
    data.is_diagnosed = result;
    data.gender = gender;
    data.patient_id = searchByPatientId;
    data.min_age = age == "1" ? 16 : age == "2" ? 25 : age == "3" ? 50 : "";
    data.max_age = age == "1" ? 25 : age == "2" ? 50 : age == "3" ? 100 : "";
    data.id = findingsIds?.length > 0 ? findingsIds.toString() : "";
    let url = URLS.CXR_REPORT + "?page=" + currentPage;

    makePostRequest(url, null, data, null).then((res) => {
      setSelectedOptions([]);
      setStartDate();
      setEndDate();
      setResult("");
      setGender("");
      setAge("");
      setSelectedOptions([]);
      setSearchByPatientId("");
      if (res.status) {
        setLoading(false);
        setReportsList(res?.cxr?.data);
        setCount(
          Math.ceil(res?.cxr?.total / parseInt(limitvalue ? limitvalue : 10))
        );
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (currentPage > 1) {
      getReportsList();
    } else if (!searchByPatientId) {
      setCurrentPage(1);
      getReportsList();
    }
  }, [limitvalue, currentPage, searchByPatientId]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= count) {
      setCurrentPage(pageNumber);
    }
  };

  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchModal = () => {
    setSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setSearchModal(false);
  };

  const toggleModal = () => {
    setSearchModal(!searchModal);
    setSearchByPatientId("");
    setStartDate();
    setEndDate();
    setResult("");
    setGender("");
    setAge("");
    setSelectedOptions([]);
  };

  const handleSearchByPatientId = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setStartDate();
    setEndDate();
    setResult("");
    setGender("");
    setAge("");
    setSelectedOptions([]);
    getReportsList();
  };

  const handleApplyFilters = (e) => {
    setCurrentPage(1);
    getReportsList();
    setSearchModal(false);
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    getReportsList();
    setStartDate();
    setEndDate();
    setResult("");
    setGender("");
    setAge("");
    setSelectedOptions([]);
    setSearchModal(false);
  };

  const gettDiseaseList = () => {
    let data = {};
    makePostRequest(URLS.GET_DISEASE, null, data, null).then((res) => {
      if (res.status) {
        setFindingsData(res?.getDisease);
      } else {
      }
    });
  };

  useEffect(() => {
    gettDiseaseList();
  }, []);

  const options = [];

  findingsData?.length > 0 &&
    findingsData.forEach((val, i) => {
      options.push({ label: val.id, name: val.disease });
    });

  // console.log(selectedOptions, "selected options");

  //download url
  const downloadUrl = (url) => {
    // console.log(url.split("/")[4]);

    // e.preventDefault();

    if (url) {
      let FileName = url.split("/");

      forceDownload(url, FileName[4]);
    } else {
      toast.error("No url available for download");
    }
  };

  useEffect(() => {
    if (searchByPatientId?.length >= 2) {
      setCurrentPage(1);
      getReportsList();
    }
  }, [searchByPatientId]);

  const [scroll, setScroll] = useState(false);
  const [scrollPagination, setScrollPagination] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPagination(window.scrollY > 80);
    });
  }, []);

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    enableScroll();
  }, []);

  const handleChildFunc = (option) => {
    if (childRef.current) {
      childRef.current.handleOptionClick(option);
    }
  };

  return (
    <>
      <Head title="Report List"></Head>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="search-type-custom search-filter">
              {/* <BlockHeadContent>
              <BlockTitle page> Users</BlockTitle>
              <BlockDes className="text-soft">
                You have total {customerListData.length} users
              </BlockDes>
            </BlockHeadContent> */}
              <BlockHeadContent>
                <div className="search-filter-custom">
                  <form onSubmit={handleSearchByPatientId}>
                    <a
                      class="search-button-new"
                      onClick={handleSearchByPatientId}
                    >
                      <div class="icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </div>
                    </a>
                    <input
                      type="search"
                      name="patientid"
                      value={searchByPatientId}
                      placeholder="Search by patient ID"
                      onChange={(e) => {
                        setSearchByPatientId(e.target.value);
                      }}
                    />
                    <a class="search-button" onClick={handleSearchModal}>
                      <div class="icon">
                        <FontAwesomeIcon icon={faSliders} />
                      </div>
                    </a>
                  </form>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <div className={` outerBox ${scroll ? "bg-blackss" : "bg-whites"}`}>
              {reportsList?.length > 0 ? (
                <ReactDataTable
                  data={reportsList}
                  columns={columns}
                  className="nk-tb-list"
                  searchable={false}
                  customStyles={customStyles}
                />
              ) : (
                ""
              )}
              {reportsList?.length > 0 ? (
                <div className="pagination-custom-container">
                  <div
                    className="dataTables_length"
                    id="DataTables_Table_0_length"
                  >
                    <label>
                      {/* <span className="d-none d-sm-inline-block">Show</span> */}
                      <div className="form-control-select">
                        {" "}
                        <select
                          name="DataTables_Table_0_length"
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={HandleLimitValue}
                          value={limitvalue}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>{" "}
                      </div>
                    </label>
                  </div>

                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={count}
                    onPageChange={handlePageChange}
                  />
                </div>
              ) : (
                <div>No Data Found</div>
              )}
            </div>
          </Block>
        </Content>
      )}
      <Modal
        isOpen={searchModal}
        className="popup-container search-box--popup-container"
        backdrop={true}
        toggle={toggleModal}
      >
        {/* <ModalHeader
          close={
            <button
              className="close"
              onClick={() => {
                setSearchModal(false);
              }}
            >
              <Icon name="cross" />
            </button>
          }
        ></ModalHeader> */}

        <div className="card-inner report-popup">
          <BlockHeadContent>
            <BlockHead size="lg">
              <div className="is-alter">
                <div className="search-filter-custom">
                  <form
                    onClick={() => {
                      handleCloseSearchModal();
                    }}
                  >
                    <a class="search-button-new">
                      <div class="icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </div>
                    </a>
                    <input type="search" placeholder="Search by patient ID" />
                    <a class="search-button" onClick={handleSearchModal}>
                      <div class="icon">
                        <FontAwesomeIcon icon={faSliders} />
                      </div>
                    </a>
                  </form>

                  <div className="form-group">
                    <div className="form-control-wrap">
                      <label className="form-label">Date: </label>
                      <div className="date-custom">
                        <div className="date-1">
                          <input
                            type="date"
                            placeholder="user"
                            className="form-control-lg form-control"
                            value={startDate}
                            name="startdate"
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                          />
                        </div>
                        <div className="date-to">To</div>
                        <div className="date-2">
                          <input
                            type="date"
                            placeholder="user"
                            className="form-control-lg form-control"
                            value={endDate}
                            name="enddate"
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-control-wrap">
                      <label className="form-label">Result: </label>
                      <div className="result-custom">
                        <div className="con-1">
                          <button
                            className={`btn ${
                              result == "1" ? "btn-active" : ""
                            }`}
                            onClick={() => {
                              setResult("1");
                            }}
                          >
                            Positive
                          </button>
                        </div>

                        <div className="con-2">
                          <button
                            className={`btn ${
                              result == "0" ? "btn-active" : ""
                            }`}
                            onClick={() => {
                              setResult("0");
                            }}
                          >
                            Negative
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-control-wrap finding">
                      <label className="form-label">Finding: </label>
                      <div className="date-custom">
                        {/* <div className="date-1">  */}
                        <MultiSelectDropdown
                          options={options}
                          labelKey="name"
                          onSelect={handleSelect}
                          ref={childRef}
                        />
                        {selectedOptions?.length > 0 && (
                          <ul>
                            {/* <span>Selected Finding Values:</span> */}
                            {selectedOptions.map(
                              (findingsval, findingsindex) => {
                                return (
                                  <li key={findingsindex + 1}>
                                    {findingsval.name}{" "}
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      className="close-custom"
                                      onClick={() => {
                                        handleChildFunc(findingsval);
                                      }}
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}

                        {/* </div> */}
                      </div>
                    </div>

                    <div className="form-control-wrap">
                      <label className="form-label">Gender: </label>
                      <div className="result-custom">
                        <div className="con-1">
                          <button
                            className={`btn ${
                              gender == "1" ? "btn-active" : ""
                            }`}
                            onClick={() => {
                              setGender("1");
                            }}
                          >
                            Male
                          </button>
                        </div>

                        <div className="con-2">
                          <button
                            className={`btn ${
                              gender == "2" ? "btn-active" : ""
                            }`}
                            onClick={() => {
                              setGender("2");
                            }}
                          >
                            Female
                          </button>
                        </div>

                        <div className="con-3">
                          <button
                            className={`btn ${
                              gender == "3" ? "btn-active" : ""
                            }`}
                            onClick={() => {
                              setGender("3");
                            }}
                          >
                            Others
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-control-wrap">
                      <label className="form-label">Age: </label>
                      <div className="result-custom">
                        <div className="con-1">
                          <button
                            className={`btn ${age == "1" ? "btn-active" : ""}`}
                            onClick={() => {
                              setAge("1");
                            }}
                          >
                            16-25
                          </button>
                        </div>

                        <div className="con-2">
                          <button
                            className={`btn ${age == "2" ? "btn-active" : ""}`}
                            onClick={() => {
                              setAge("2");
                            }}
                          >
                            25-50
                          </button>
                        </div>

                        <div className="con-3">
                          <button
                            className={`btn ${age == "3" ? "btn-active" : ""}`}
                            onClick={() => {
                              setAge("3");
                            }}
                          >
                            {" "}
                            50 +
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="clear-apply-container">
                      <div className="form-save-div">
                        <button
                          className="btn"
                          onClick={() => {
                            handleClearFilters();
                          }}
                        >
                          Clear
                        </button>
                      </div>

                      <div className="form-save-div">
                        <button
                          className="btn active"
                          onClick={handleApplyFilters}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BlockHead>
          </BlockHeadContent>
        </div>
      </Modal>
    </>
  );
};

export default ReportsList;
