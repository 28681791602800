// src/components/MultiSelectDropdown.js
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./MultiSelectDropdown.css";
import dropDownDownArrow from "../../../images/dropdown-down-arrow.svg";

const MultiSelectDropdown = forwardRef((props, ref) => {
  const { options, labelKey, onSelect } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [applySticky, setApplySticky] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useImperativeHandle(ref, () => ({
    handleOptionClick(option) {
      if (selectedOptions.some((obj) => obj.label == option.label)) {
        let newvalue = [...selectedOptions];
        selectedOptions.forEach((item, index) => {
          if (item.label == option.label) {
            newvalue.splice(index, 1);
            setSelectedOptions(newvalue);
            onSelect(newvalue);
          }
        });
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    },
  }));

  const handleOptionClick = (option) => {
    if (selectedOptions.some((obj) => obj.label == option.label)) {
      let newvalue = [...selectedOptions];
      selectedOptions.forEach((item, index) => {
        if (item.label == option.label) {
          newvalue.splice(index, 1);
          setSelectedOptions(newvalue);
        }
      });
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleApplyClick = () => {
    onSelect(selectedOptions);
    toggleDropdown();
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (isDropdownOpen && !event.target.closest(".multi-select-dropdown")) {
        toggleDropdown();
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isDropdownOpen]);

  const filteredOptions = options.filter((option) =>
    option[labelKey].toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleScroll = (e) => {
    // Check if the dropdown list is scrolled (e.g., when scrollTop is greater than 0)
    if (e.target.scrollTop > 0) {
      setApplySticky(true);
    } else {
      setApplySticky(false);
    }
  };

  return (
    <div className="multi-select-dropdown custom-select" ref={dropdownRef}>
      {!isDropdownOpen && (
        <div className="dropdown-header" onClick={toggleDropdown}>
          Select findings
          <img src={dropDownDownArrow} />
        </div>
      )}
      {isDropdownOpen && (
        <div className="dropdown-content " onScroll={handleScroll}>
          <input
            type="search"
            placeholder="Select findings"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div
            className={`apply-section ${applySticky ? "sticky" : ""}`}
            id="apply-button"
          >
            <button onClick={handleApplyClick}>Apply</button>
          </div>
          {filteredOptions.map((option) => (
            <div
              key={option[labelKey]}
              className={`dropdown-option ${
                selectedOptions.some((obj) => obj.label === option.label)
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              <input
                type="checkbox"
                checked={
                  selectedOptions.some((obj) => obj.label === option.label)
                    ? true
                    : false
                }
                readOnly
              />
              {option[labelKey]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default MultiSelectDropdown;
