import React, { useState, useEffect, useRef } from "react";
// import Logo from "../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import DragImage from "../../../images/drag-item.png";
import chooseFileIcon from "../../../images/choose-file.svg";
import CloudUploadIcon from "../../../images/cloud-upload.svg";
import ChevronExpandIcon from "../../../images/chevron-expand.svg";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../images/image-8@2x.png";
import { URLS } from "../../../constants/urls";
import {
  makePostRequest,
  makeGetRequest,
  BASE_URL,
} from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import CloudUpload from "../../../images/cloud-upload.png";
import Group from "../../../images/group.png";
import Chevronexpand from "../../../images/chevron-expand.png";
import ClaritySuccess from "../../../images/clarity_success-standard-solid.svg";
import CircleError from "../../../images/si-glyph_circle-error.svg";
import { isValidFileUploaded } from "../../../utils/Utils";
import LoadingScreen from "../../loader/LoadingScreen";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Bone = () => {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [cxrImage, setCxrImage] = useState("");
  const [boneImageUrl, setboneImageUrl] = useState("");
  const [saveBoneImageToDb, setsaveBoneImageToDb] = useState(false);
  const [state, setState] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const token = sessionStorage.getItem("auth-token");
  const navigate = useNavigate();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      let response = isValidFileUploaded(selectedFile);
      if (response) {
        setCxrImage(selectedFile);

        let cxrImgurl = URL.createObjectURL(selectedFile);
        setboneImageUrl(cxrImgurl);
      } else {
        toast.error("Supported format for file is not correct");
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];

    if (file) {
      let response = isValidFileUploaded(file);

      if (response) {
        setCxrImage(file);
        let cxrImgurl = URL.createObjectURL(file);
        setboneImageUrl(cxrImgurl);
      } else {
        toast.error("Supported format for file is not correct");
      }
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const handleRemoveCxrImage = () => {
    setboneImageUrl("");
    setCxrImage("");
    setsaveBoneImageToDb(false);
  };

  const handleSelectCxrImage = () => {
    setsaveBoneImageToDb(true);
  };

  const handleCxrForm = async () => {
    let valid = 0;
    let errors = {};
    if (!state.patientid) {
      errors.patientid = "Please enter patient id";
      valid++;
    }

    if (!state.name) {
      errors.name = "Please enter name";
      valid++;
    }
    if (!state.age) {
      errors.age = "Please enter age";
      valid++;
    }
    if (!state.gender) {
      errors.gender = "Please select gender";
      valid++;
    }
    if (!state.body_part) {
      errors.body_part = "Please select body part";
      valid++;
    }

    if (cxrImage == "") {
      toast.error("Please select file");
      valid++;
    }

    if (cxrImage && !saveBoneImageToDb) {
      toast.error("Please select ok button to save selected file");
      valid++;
    }
    if (state.age) {
      if (isNaN(state.age)) {
        errors.age = "Please enter number";
        valid++;
      } else if (state.age < 0) {
        errors.age = "Negative number not allowed";
        valid++;
      }
    }

    setValidationErrors({ ...validationErrors, errors: errors });

    if (valid == 0) {
      setLoading(true);
      let formData = new FormData();
      formData.append("patient_id", state.patientid);
      formData.append("name", state.name);
      formData.append("gender", state.gender);
      formData.append("body_part", state.body_part);
      formData.append("age", state.age);
      formData.append("image", cxrImage);

      await axios
        .post(BASE_URL + URLS.BONE_FORM, formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            //  console.log(res?.data?.patient_details, "patient details");
            // toast.success(res?.data?.message);
            navigate("/edit-report", {
              state: {
                inputImgUrl: res?.data?.input_image_url,
                outputImgUrl: res?.data?.output_image_url,
                data: res?.data?.response,
                cxr: res?.data?.patient_details,
              },
            });
          } else {
            toast.error(res?.data?.message);
          }
          console.log(res);
        })
        .catch((error) => {
          setLoading(false);
          navigate("/error-page");
          console.log("errrr", error);
        });
    }
  };

  let { errors } = validationErrors;

  return (
    <>
      <Head title="Bone &amp; Patient" />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Content>
          <div className="nk-block-head nk-block-head-sm cxr-custom">
            {/* <PreviewCard className="card-bordered card-style"> */}
            <div className="nk-block-between g-3">
              <div className="nk-block-content">
                <img src={CloudUploadIcon} className="upload-image-con" />
                <b className="nk-block-title title">Upload Bone Image</b>
              </div>
            </div>
            <Block>
              <div className="row g-gs">
                <div className="col-md-6 col-xxl-6 upload-Image-box">
                  <div className="card-bordered pricing  card">
                    <div className="content-container">
                      {!boneImageUrl && (
                        <div className="drag-container item">
                          <img src={DragImage} />
                        </div>
                      )}

                      <div className="new-upcoming">
                        {boneImageUrl && (
                          <>
                            <img
                              class="image-14-icon"
                              alt=""
                              src={boneImageUrl}
                            />
                            {!saveBoneImageToDb && (
                              <div className="right-wrong">
                                <img
                                  class="claritysuccess-standard-solid-icon"
                                  alt=""
                                  src={ClaritySuccess}
                                  onClick={handleSelectCxrImage}
                                />

                                <img
                                  class="si-glyphcircle-error-icon"
                                  alt=""
                                  src={CircleError}
                                  onClick={handleRemoveCxrImage}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <div
                        className={`drag-itemText item  ${
                          isDragging ? "dragging" : ""
                        }`}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                      >
                        {!boneImageUrl && (
                          <>
                            <h3>Drag and drop here</h3>
                            <h3 className="or-section">OR</h3>
                            <label class="filelabel">
                              {/* <span class="title">Add File</span> */}
                              <button
                                className="form--control btn"
                                onClick={handleButtonClick}
                              >
                                <img src={chooseFileIcon} />
                                Choose File
                              </button>
                              <input
                                type="file"
                                name="image"
                                className="icon-button1"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={handleFileSelect}
                              />
                            </label>
                          </>
                        )}
                      </div>
                      {/* <div className="select-fileItem item">
                      
                    </div> */}
                    </div>
                    {!boneImageUrl && (
                      <div className="format-custom">
                        <h3>
                          <span>Supported Formats -</span> DICOM, PNG, JPEG
                          (.dcm, .png, .jpeg)
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6">
                  <div className="form-custom">
                    <form>
                      <div className="form-group">
                        <label for="first">Patient ID *</label>
                        <input
                          type="text"
                          name="patientid"
                          value={state.patientid}
                          onChange={handleInput}
                        />
                        <span className="error">{errors?.patientid} </span>
                      </div>

                      <div className="form-group">
                        <label for="first">Name *</label>
                        <input
                          type="text"
                          name="name"
                          value={state.name}
                          onChange={handleInput}
                        />
                        <span className="error">{errors?.name} </span>
                      </div>

                      <div className="form-group">
                        <label for="first">Gender *</label>

                        <select
                          className="gender-input"
                          id="gender"
                          name="gender"
                          value={state.gender}
                          onChange={handleInput}
                        >
                          <img
                            src={ChevronExpandIcon}
                            className="gender-arrow-upDown"
                            width={32}
                            height={32}
                            onChange={handleInput}
                          />
                          <option value="" className="gender-new-bg">
                            Select your gender
                          </option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">others</option>
                        </select>

                        <span className="error">{errors?.gender} </span>
                      </div>

                      <div className="form-group">
                        <label for="first">Body Part *</label>

                        <select
                          className="gender-input"
                          id="body_part"
                          name="body_part"
                          value={state.body_part}
                          onChange={handleInput}
                        >
                          <img
                            src={ChevronExpandIcon}
                            className="gender-arrow-upDown"
                            width={32}
                            height={32}
                            onChange={handleInput}
                          />
                          <option value="" className="gender-new-bg">
                            Select your Body Part
                          </option>
                          <option value="1">Hand</option>
                          <option value="2">Spine</option>
                          <option value="3">Default</option>
                        </select>

                        <span className="error">{errors?.gender} </span>
                      </div>

                      <div className="form-group">
                        <label for="first">Age *</label>
                        <input
                          type="text"
                          id="age"
                          name="age"
                          placeholder="Yrs"
                          value={state.age}
                          onChange={handleInput}
                        />
                        <span className="error">{errors?.age} </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </PreviewCard> */}
            </Block>
            <div className="nk-block-between new-footer-padding    g-3">
              <div className="nk-block-content  ">
                <button
                  className="btn submit-custom-btn"
                  onClick={handleCxrForm}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Content>
      )}
    </>
  );
};
export default Bone;
