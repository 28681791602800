import axios from "axios";
export const BASE_URL = "https://api.zekalabs.io/api";

export const makeGetRequest = async (url, queryParams = {}, headers = {}) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.get(BASE_URL + url, {
      params: queryParams,
      headers: requestHeaders,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const makePostRequest = async (url, queryParams, body, headers = {}) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.post(BASE_URL + url, body, {
      params: queryParams,
      headers: requestHeaders,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const makePutRequest = async (url, queryParams, body, headers = {}) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.put(BASE_URL + url, body, {
      params: queryParams,
      headers: requestHeaders,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const makeDeleteRequest = async (
  url,
  queryParams,
  body,
  headers = {}
) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.delete(BASE_URL + url, {
      params: queryParams,
      headers: requestHeaders,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getHeaders = (headers = {}) => {
  const token = sessionStorage.getItem("auth-token");
  let headerObj = {
    "Content-Type": "application/json",
    ...headers,
  };
  if (token) {
    headerObj.Authorization = `Bearer ${token}`;
  }
  return headerObj;
};
