import React, { useState } from "react";
// import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import LoginArrow from "../../images/login_arrow.png";

import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  PreviewCard,
  Icon,
} from "../../components/Component";
import { Link } from "react-router-dom";
import Logo from "../../images/image-8@2x.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { makePostRequest } from "../../service/api";
import { URLS } from "../../constants/urls";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../loader/LoadingScreen";
import { validatePassword } from "../../utils/Utils";

const ForgotPassword = () => {
  const [state, setState] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [passState1, setPassState1] = useState(false);
  const [passState2, setPassState2] = useState(false);
  const navigate = useNavigate();

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // setLoading(true);
    let valid = 0;
    let errors = {};

    if (!state.password) {
      errors.password = "Please enter password";
      valid++;
    }

    if (!state.newpassword) {
      errors.newpassword = "Please enter new  password";
      valid++;
    }
    if (!state.confirmpassword) {
      errors.confirmpassword = "Please re-enter password";
      valid++;
    }

    if (state.newpassword && validatePassword(state.newpassword) == false) {
      errors.newpassword =
        "Password must have at least 8 characters including letters and numbers.";
      valid++;
    }

    if (
      state.newpassword &&
      state.confirmpassword &&
      state.newpassword != state.confirmpassword
    ) {
      errors.confirmpassword = "New Password and re-enter password must match";
      valid++;
    }

    setValidationErrors({ ...validationErrors, errors: errors });

    if (valid == 0) {
      setLoading(true);
      let data = {
        old_password: state.password,
        new_password: state.newpassword,
        new_confirm_password: state.confirmpassword,
      };
      makePostRequest(URLS.UPDATE_PASSWORD, null, data, null)
        .then((res) => {
          setLoading(false);
          if (res.status) {
            toast.success(res.message);
            navigate("/auth-login");
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let { errors } = validationErrors;

  return (
    <>
      <Head title="Reset-Password" />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <i class="fa-regular fa-arrow-right"></i>
            </Link>
          </div>
          {/* <PreviewCard className="card-bordered" bodyClass="card-inner-lg"> */}
          {/* <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>
                  If you forgot your password, well, then we’ll email you
                  instructions to reset your password.
                </p>
              </BlockDes>
            </BlockContent>
          </BlockHead> */}
          <form className="form-custom">
            <div className="form-group">
              {/* <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Email
              </label>
            </div> */}
              <a
                href="#password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState(!passState);
                }}
                className={`form-icon lg form-icon-right passcode-switch ${
                  passState ? "is-hidden" : "is-shown"
                }`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
              <input
                type={passState ? "text" : "password"}
                className={`form-control-lg form-control ${
                  passState ? "is-hidden" : "is-shown"
                }`}
                id="default-01"
                name="password"
                value={state?.password}
                onChange={handleInput}
                placeholder="Enter old password"
              />
              <span className="error"> {errors?.password}</span>
            </div>
            <div className="form-group">
              {/* <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Email
              </label>
            </div> */}
              <a
                href="#password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState1(!passState1);
                }}
                className={`form-icon lg form-icon-right passcode-switch ${
                  passState1 ? "is-hidden" : "is-shown"
                }`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
              <input
                type={passState1 ? "text" : "password"}
                className={`form-control-lg form-control ${
                  passState1 ? "is-hidden" : "is-shown"
                }`}
                id="newpassword"
                name="newpassword"
                value={state?.newpassword}
                onChange={handleInput}
                placeholder="Enter new password"
              />
              <span className="error"> {errors?.newpassword}</span>
            </div>
            <div className="form-group">
              {/* <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Email
              </label>
            </div> */}
              <a
                href="#password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState2(!passState2);
                }}
                className={`form-icon lg form-icon-right passcode-switch ${
                  passState2 ? "is-hidden" : "is-shown"
                }`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
              <input
                type={passState2 ? "text" : "password"}
                className={`form-control-lg form-control ${
                  passState2 ? "is-hidden" : "is-shown"
                }`}
                id="confirmpassword"
                name="confirmpassword"
                value={state?.confirmpassword}
                onChange={handleInput}
                placeholder="Re enter new password"
              />
              <span className="error"> {errors?.confirmpassword}</span>
            </div>
            <div className="form-group register-custom">
              <Button
                color=""
                size="lg"
                className="btn-block"
                onClick={(e) => handleFormSubmit(e)}
              >
                Submit
              </Button>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="btn btn-lg login_arrow"
              />
            </div>
          </form>
          {/* <div className="form-note-s2 text-center pt-4">
          <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
            <strong>Return to login</strong>
          </Link>
        </div> */}
          {/* </PreviewCard> */}
        </Block>
      )}

      {/* <AuthFooter /> */}
    </>
  );
};
export default ForgotPassword;
