import Router from "./route/Index";
//  import "./style.css";
import "./style.scss";

import ThemeProvider from "./layout/provider/Theme";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style

const App = () => {
  const [path, setPath] = useState(window.location.pathname);
  const auth = sessionStorage.getItem("auth-token");

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  if (
    auth &&
    (path === "/auth-login" ||
      path === "/auth-register" ||
      path === "/terms-conditions" ||
      path === "/auth-reset")
  ) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <ToastContainer />
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </>
  );
};
export default App;
