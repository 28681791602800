import React, { useState, useEffect, useRef } from "react";
// import Logo from "../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import reportImg from "../../../images/report.png";
import CopyLinkImg from "../../../images/LinkCopy.svg";
import copyUrl from "../../../images/copy-url.svg";
import WatsappImg from "../../../images/dashicons_whatsapp.svg";
import mailIcon from "../../../images/mail-icon.svg";

import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faEye,
  faEyeSlash,
  faCircleHalfStroke,
  faSun,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faArrowsUpDownLeftRight,
  faAnglesRight,
  faShareNodes,
  faDownload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "../../../images/image-8@2x.png";

import lowBrightness from "../../../images/brightness-low.svg";
import highBrightness from "../../../images/brightness-high.svg";
import zoomIn from "../../../images/zoom-in.svg";
import zoomOut from "../../../images/zoom-out.svg";
import arrowMove from "../../../images/arrows-move.svg";
import { URLS } from "../../../constants/urls";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { capitalizeSentences } from "../../../utils/Utils";
import LoadingScreen from "../../loader/LoadingScreen";
import {
  Spinner,
  Modal,
  ModalFooter,
  ModalHeader,
  Card,
  Form,
} from "reactstrap";
import MultiSelectDropdown from "../common/MultiSelectDropdown";
import ChevroletRightIcon from "../../../images/chevron-double-right.png";
import ChevroletLeftIcon from "../../../images/chevron-double-left.png";
import ChevroletDownIcon from "../../../images/chevron-double-down.svg";
import ChevroletUpIcon from "../../../images/chevron-double-up.svg";
import shareIcon from "../../../images/share-fill.png";
import downloadIcon from "../../../images/download.svg";
import Tooltip from "../common/Tooltip";
import { forceDownload } from "../../../utils/Utils";

// import Draggable from "react-draggable";

const EditReport = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isOutput, setIsOutput] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [cxrData, setCxrData] = useState({});

  const [scale, setScale] = useState(1);
  const [brightness, setBrightness] = useState(100);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isEditReport, setIsEditReport] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOutPutScreen, setIsOutputScreen] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [findingsData, setFindingsData] = useState([]);

  const childRef = useRef();

  let navigate = useNavigate();

  const handleSelect = (selected) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    //setData(location?.state?.data);
    setCxrData(location?.state?.cxr);
  }, [location?.state?.data, location?.state?.cxr]);

  const removeUnderscores = (inputString) => {
    return inputString.replace(/_/g, " ");
  };

  const getReportById = () => {
    let data = {};
    setLoading(true);
    let cxrid = id || location?.state?.cxr?.id;

    let url = URLS.CXR_REPORT + "/" + cxrid;

    makePostRequest(url, null, data, null)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          setData(res?.response);
          setCxrData(res?.cxr);
          //console.log(res);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        navigate("/error-page");
        console.log(error);
      });
  };

  useEffect(() => {
    if (id || location?.state?.cxr?.id) {
      getReportById();
    }
  }, [id || location?.state?.cxr?.id]);

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale >= 0.2) {
      setScale(scale - 0.1);
    }
  };

  const increaseBrightness = () => {
    if (brightness < 200) {
      setBrightness(brightness + 10);
    }
  };

  const decreaseBrightness = () => {
    if (brightness > 0) {
      setBrightness(brightness - 10);
    }
  };

  const toggleFullScreen = () => {
    setIsImageMovable(!isImageMovable);
    setPosition({ top: 0, left: 0 });

    // setIsFullScreen(!isFullScreen);
  };

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if (event.key === "Escape") {
  //       setIsFullScreen(false);
  //     }
  //   };

  //   if (isFullScreen) {
  //     // Attach the event listener when in full-screen mode
  //     window.addEventListener("keydown", handleKeyPress);
  //   } else {
  //     // Remove the event listener when exiting full-screen mode
  //     window.removeEventListener("keydown", handleKeyPress);
  //   }

  //   return () => {
  //     // Cleanup: Remove the event listener when the component unmounts
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [isFullScreen]);

  const handleEditReport = () => {
    setIsEditReport(true);
    setEditModal(true);
  };

  const handleClose = () => {
    setIsEditReport(false);
    setEditModal(false);
  };

  const handleEditReportData = () => {
    if (selectedOptions?.length == 0) {
      toast.error("Please select atleast one finding");
      return;
    }
    let cxrid = id || location?.state?.cxr?.id;
    let obj = {};
    selectedOptions.length > 0 &&
      selectedOptions.forEach((val) => {
        const index = findingsData.findIndex((obj) => obj.disease == val.name);
        if (index !== -1) {
          // let obj = {};
          let disease_id = findingsData[index].id;
          let disease_key = findingsData[index].disease_key;
          obj[disease_id] = disease_key;
        }
      });

    let data = { data: obj };

    setLoading(true);
    makePostRequest(URLS.CXR_EDIT_REPORT + "/" + cxrid, null, data, null)
      .then((res) => {
        setSelectedOptions([]);
        //console.log("response::", res);
        setLoading(false);
        if (res.status) {
          toast.success(res.message);
          setEditModal(false);
          setIsEditReport(false);
          setSelectedOptions([]);
          getReportById();
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        navigate("/error-page");
        console.log(error);
      });
  };

  // const options = [];

  // data &&
  //   Object.keys(data).forEach((key, i) => {
  //     Object.keys(data[key]).forEach((keys, index) => {
  //       options.push({ label: keys, name: data[key][keys].name });
  //     });
  //   });

  const options = [];

  findingsData?.length > 0 &&
    findingsData.forEach((val, i) => {
      options.push({ label: val.id, name: val.disease });
    });

  const isShowHideInputOutputScreen = () => {
    if (isOutPutScreen == true) {
      setIsOutputScreen(false);
    } else {
      setIsOutputScreen(true);
    }
  };

  //download url
  const downloadUrl = (url) => {
    // console.log(url.split("/")[4]);
    // e.preventDefault();
    if (url) {
      let FileName = url.split("/");
      forceDownload(url, FileName[4]);
    } else {
      toast.error("No url available for download");
    }
  };

  const shareLink = () => {
    const linkToShare = cxrData?.download_url;

    // Generate the WhatsApp share URL.
    const whatsappShareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      linkToShare
    )}`;

    // Open the WhatsApp share URL in a new window.
    window.open(whatsappShareURL, "_blank");
  };

  const copyLink = async () => {
    if (cxrData?.download_url) {
      const downloadurl = `${cxrData?.download_url}`;

      const textArea = document.createElement("textarea");
      textArea.value = downloadurl;
      document.body.appendChild(textArea);

      textArea.select();

      document.execCommand("copy");

      document.body.removeChild(textArea);

      toast.success("The link has been copied!");
    } else {
      toast.success("Something went wrong.");
    }

    // if (cxrData?.download_url) {
    //   let url = cxrData.download_url;
    //   await navigator.clipboard.writeText(url);
    //   toast.success("The link has been copied.");
    // } else {
    //   toast.success("Something went wrong.");
    // }
  };

  const handleShareEmail = () => {
    // Replace these placeholders with the actual recipient's email address, subject, and link.
    const recipientEmail = "";
    const emailSubject = "Download this link!";
    const linkToShare = cxrData?.download_url;

    // Create the mailto link.
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(linkToShare)}`;

    // Open the user's default email client with the pre-filled email.
    window.location.href = mailtoLink;
  };

  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });
  const [isImageMovable, setIsImageMovable] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isScrollEnabled, setScrollEnabled] = useState(true);

  const containerRef = useRef(null);
  const imageRef = useRef(null);

  const startMovement = (e) => {
    e.preventDefault();
    if (isImageMovable) {
      setIsMoving(true);
      disableScroll();
    }
  };

  const stopMovement = () => {
    setIsMoving(false);
    disableScroll();
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    if (isImageMovable) {
      setIsMoving(true);
      setScrollEnabled(false);
      disableScroll();
    }
  };

  const handleTouchEnd = () => {
    setIsMoving(false);
    setScrollEnabled(true);
    enableScroll();
  };

  const moveImage = (e) => {
    if (isMoving) {
      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();

      const image = imageRef.current;
      const imageRect = image.getBoundingClientRect();

      const imageWidth = imageRect.width; // Width of the image
      const imageHeight = imageRect.height; // Height of the image

      // Calculate the new top and left positions
      const newTop = e.clientY - containerRect.top - imageHeight / 2;
      const newLeft = e.clientX - containerRect.left - imageWidth / 2;

      setPosition({
        top: newTop,
        left: newLeft,
      });
    }
  };

  const handleTouchMove = (e) => {
    if (isMoving) {
      const touch = e.touches[0];
      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();

      const image = imageRef.current;
      const imageRect = image.getBoundingClientRect();

      const imageWidth = imageRect.width; // Width of the image
      const imageHeight = imageRect.height; // Height of the image

      // Calculate the new top and left positions
      const newTop = touch.clientY - containerRect.top - imageHeight / 2;
      const newLeft = touch.clientX - containerRect.left - imageWidth / 2;

      setPosition({
        top: newTop,
        left: newLeft,
      });

      // setPosition({
      //   top: touch.clientX - e.target.offsetWidth / 2,
      //   left: touch.clientY - e.target.offsetHeight / 2,
      // });
    }
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const gettDiseaseList = () => {
    let data = {};
    makePostRequest(URLS.GET_DISEASE, null, data, null).then((res) => {
      if (res.status) {
        setFindingsData(res?.getDisease);
      } else {
      }
    });
  };

  useEffect(() => {
    gettDiseaseList();
  }, []);

  const handleChildFunc = (option) => {
    if (childRef.current) {
      childRef.current.handleOptionClick(option);
    }
  };

  return (
    <>
      <Head title="Edit Report" />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Content>
          <div
            className="nk-block-head nk-block-head-sm output-custom"
            ref={containerRef}
            // style={{
            //   position: "relative",
            //   overflow: "hidden",
            // }}
            onMouseUp={stopMovement}
            onMouseLeave={stopMovement}
            onTouchEnd={handleTouchEnd}
            onMouseMove={moveImage}
            onTouchMove={handleTouchMove}
          >
            {/* <PreviewCard className="card-bordered card-style"> */}
            {/* <div className="nk-block-between g-3">
            <div className="nk-block-content">
              <FontAwesomeIcon icon={faCloudArrowUp} className="upload-icon" />
              <h3 className="nk-block-title title">Upload Image</h3>
            </div>
          </div> */}
            <Block>
              <div className="row g-gs">
                <div
                  className={` flex-output-main ${
                    isOutPutScreen ? "abc" : "xyz"
                  }`}
                >
                  <div className="zoom-flex">
                    <div className="output-modify-container">
                      <div className=" top-sec-1">
                        <div className="alter-action-btn">
                          <div className="icons-alternate-circle">
                            <Tooltip text="Increase Brightness">
                              <div className="circle">
                                {/* <FontAwesomeIcon
                                icon={faCircleHalfStroke}
                                onClick={increaseBrightness}
                              /> */}
                                {/* <FontAwesomeIcon
                                  icon={faSun}
                                  onClick={increaseBrightness}
                                /> */}
                                <img
                                  src={highBrightness}
                                  onClick={increaseBrightness}
                                  width={32}
                                  height={32}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip text="Decrease Brightness">
                              <div className="circle">
                                <img
                                  src={lowBrightness}
                                  onClick={decreaseBrightness}
                                  width={32}
                                  height={32}
                                />
                              </div>
                            </Tooltip>

                            <Tooltip text="Zoom In">
                              <div className="zoom">
                                {/* <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> */}
                                <img
                                  src={zoomIn}
                                  onClick={handleZoomIn}
                                  width={32}
                                  height={32}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip text="Zoom Out">
                              <div className="zoom">
                                {/* <FontAwesomeIcon
                                  icon={faMagnifyingGlassMinus}
                                /> */}
                                <img
                                  src={zoomOut}
                                  onClick={handleZoomOut}
                                  width={32}
                                  height={32}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip text="Move">
                              <div className="arrow">
                                {/* <FontAwesomeIcon
                                  icon={faArrowsUpDownLeftRight}
                                /> */}
                                <img
                                  src={arrowMove}
                                  onClick={toggleFullScreen}
                                  width={32}
                                  height={32}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="output-flex">
                    <div className="output--screen-container">
                      <div className="upload-Image-box">
                        <div className="card-bordered pricing  card">
                          <div className="patient-custom-container">
                            <div className="custom-hader-name">
                              <div className="patient-name">
                                <h3>
                                  {cxrData?.patient_id} |{" "}
                                  {cxrData?.patient_name || cxrData?.name} |{" "}
                                  {cxrData?.age}{" "}
                                  {cxrData?.gender == 1
                                    ? "M"
                                    : cxrData?.gender == 2
                                    ? "F"
                                    : "Other"}
                                </h3>
                              </div>
                              <div className="patient-status">
                                <button
                                  className={`btn ${
                                    cxrData?.is_diagnosed == 1
                                      ? "btn-pos"
                                      : "btn-neg"
                                  }`}
                                >
                                  {cxrData?.is_diagnosed == 1
                                    ? "Positive"
                                    : "Negative"}
                                </button>
                              </div>
                            </div>

                            <div
                              className={`report-custom-div ${
                                isFullScreen ? "full-screen" : ""
                              } `}
                            >
                              {/* <Draggable> */}
                              <img
                                src={
                                  !isOutput
                                    ? cxrData?.output_url
                                    : cxrData?.input_url
                                }
                                style={{
                                  transform: `scale(${scale})`,
                                  filter: `brightness(${brightness}%)`,

                                  top: `${position.top}px`,
                                  left: `${position.left}px`,
                                  position: "relative",
                                }}
                                ref={imageRef}
                                onMouseDown={startMovement}
                                onMouseUp={startMovement}
                                onTouchStart={handleTouchStart}
                              />
                              {/* </Draggable> */}
                            </div>
                            <div className="input-output-btn">
                              <div className="btn-out-input-custom ">
                                <button
                                  className={`btn ${
                                    !isOutput ? "active" : ""
                                  } `}
                                  onClick={() => {
                                    setIsOutput(false);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                  Output
                                </button>
                                <button
                                  className={`btn ${isOutput ? "active" : ""} `}
                                  onClick={() => {
                                    setIsOutput(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                  Input
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`new-download-flex`}>
                    <div className=" history-pat">
                      <div className="patient-history">
                        <div className="icons">
                          <div className="double-right">
                            {isOutPutScreen ? (
                              <Tooltip text="Show">
                                <img
                                  src={ChevroletLeftIcon}
                                  className="right-icon-web"
                                  width={32}
                                  height={32}
                                  onClick={() => {
                                    isShowHideInputOutputScreen();
                                  }}
                                />
                                <img
                                  src={ChevroletUpIcon}
                                  className="up-icon-mob"
                                  width={32}
                                  height={32}
                                  onClick={() => {
                                    isShowHideInputOutputScreen();
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip text="Hide">
                                <img
                                  src={ChevroletRightIcon}
                                  className="left-icon-web"
                                  width={32}
                                  height={32}
                                  onClick={() => {
                                    isShowHideInputOutputScreen();
                                  }}
                                />

                                <img
                                  src={ChevroletDownIcon}
                                  className="down-icon-mob"
                                  width={32}
                                  height={32}
                                  onClick={() => {
                                    isShowHideInputOutputScreen();
                                  }}
                                />
                              </Tooltip>
                            )}

                            {/* <FontAwesomeIcon
                              icon={faAnglesRight}
                              onClick={() => {
                                setIsOutput(!isOutPutScreen);
                              }}
                            /> */}
                            <Tooltip text="Share">
                              <img
                                src={shareIcon}
                                className="shareAll-icons-web"
                                width={32}
                                height={32}
                                onClick={() => {
                                  setSharePopup(true);
                                }}
                              />
                            </Tooltip>
                            <Tooltip text="Download">
                              <img
                                src={downloadIcon}
                                className="shareAll-icons-web"
                                width={32}
                                height={32}
                                onClick={() => {
                                  downloadUrl(cxrData?.download_url);
                                }}
                              />
                            </Tooltip>
                          </div>

                          <div className="double-right-icon">
                            <Tooltip text="Share">
                              <div
                                className="shareAll-icons"
                                onClick={() => {
                                  setSharePopup(true);
                                }}
                              >
                                <img src={shareIcon} width={32} height={32} />
                              </div>
                            </Tooltip>
                            <Tooltip text="Download">
                              <div className="downloadAll-icon">
                                <img
                                  src={downloadIcon}
                                  width={32}
                                  height={32}
                                  onClick={() => {
                                    downloadUrl(cxrData?.download_url);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="result-custon-item">
                          {data &&
                            Object.keys(data).map((key, i) => {
                              return (
                                <div className="pat-disname-heading">
                                  <div className="pat-disname" key={i + 1}>
                                    <h2>{capitalizeSentences(key)}</h2>
                                  </div>
                                  <div className="pat-disname-item">
                                    {Object.keys(data[key]).map(
                                      (keys, index) => {
                                        return (
                                          <>
                                            <div
                                              className={`disname-item`}
                                              key={index + 1}
                                            >
                                              <div
                                                className={`dis-col ${
                                                  data[key][keys].value == "Yes"
                                                    ? "active"
                                                    : ""
                                                }`}
                                              >
                                                <h3>{data[key][keys].name}</h3>
                                              </div>
                                              <div
                                                className={`dis-col ${
                                                  data[key][keys].value == "Yes"
                                                    ? "active"
                                                    : ""
                                                }`}
                                              >
                                                <h3>{data[key][keys].value}</h3>
                                              </div>
                                            </div>
                                            <div className="pat-disname-item xyz">
                                              {data[key][keys].sub_disease !==
                                                undefined &&
                                                Object.keys(
                                                  data[key][keys].sub_disease
                                                ).map((subkeys, subindex) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className={`disname-item`}
                                                        key={subindex + 1}
                                                      >
                                                        <div
                                                          className={`dis-col ${
                                                            data[key][keys]
                                                              .sub_disease[
                                                              subkeys
                                                            ].value == "Yes"
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          <h3>
                                                            {
                                                              data[key][keys]
                                                                .sub_disease[
                                                                subkeys
                                                              ].name
                                                            }
                                                          </h3>
                                                        </div>
                                                        <div
                                                          className={`dis-col ${
                                                            data[key][keys]
                                                              .sub_disease[
                                                              subkeys
                                                            ].value == "Yes"
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          <h3>
                                                            {
                                                              data[key][keys]
                                                                .sub_disease[
                                                                subkeys
                                                              ].value
                                                            }
                                                          </h3>
                                                        </div>
                                                      </div>
                                                    </>
                                                  );
                                                })}
                                            </div>

                                            {/* {"     "}
                                        <div className="status-item">
                                          <h3>{data[key][keys].value}</h3>
                                        </div> */}
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>

                        <div className="nk-block-between">
                          <div className="nk-block-content">
                            <button
                              className="btn submit-custom-btn"
                              onClick={handleEditReport}
                              disabled={isEditReport ? true : false}
                            >
                              Edit Report
                            </button>
                          </div>
                        </div>

                        {/* <div className="disname-item">
                            <h3>Tuberculosis</h3>
                          </div>
                          <div className="status-item">
                            <h3>Yes</h3>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="rectangle-parent">
                <div className="group-child" />
                <button className="submit" onClick={handleCxrForm}>
                  Submit
                </button>
              </div> */}
                <></>
                {/* <b
                className={`drag-and-drop-container drop-zone ${
                  isDragging ? "dragging" : ""
                }`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                {cxrImageUrl && <img src={cxrImageUrl} />}

                {!cxrImageUrl && (
                  <span className="drag-and-drop-container1">
                    <p className="drag-and-drop">Drag and drop here</p>

                    <p className="drag-and-drop">&nbsp;</p>
                    <p className="drag-and-drop">&nbsp;</p>
                    <p className="drag-and-drop">OR</p>
                  </span>
                )}
              </b> */}

                {/* <img className="cloud-upload-icon" alt src={CloudUpload} />
              {!cxrImageUrl && (
                <div className="supported-formats-container">
                  <span className="drag-and-drop-container1">
                    <b>Supported Formats </b>
                    <span>- DICOM, PNG, JPEG (.dcm, .png, .jpeg)</span>
                  </span>
                </div>
              )} */}

                {/* <div className="desktop-upload-image-inner">
                <div className="rectangle-wrapper">
                  <div className="group-item">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          id="patientid"
                          name="patientid"
                          value={state.patientid}
                          onChange={handleInput}
                          placeholder=""
                          className="form-control-lg form-control"
                        />

                        <span className="error">{errors?.patientid} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desktop-upload-image-item" />
              <div className="patient-id">Patient ID *</div> */}

                {/* <div className="group-div">
                <div className="rectangle-wrapper">
                  <div className="group-item">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <select
                          id="gender"
                          name="gender"
                          value={state.gender}
                          className="form-select"
                          onChange={handleInput}
                        >
                          <option value="">Select your gender</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">others</option>
                        </select>

                        <span className="error">{errors?.gender} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-div" />
              <div className="gender">Gender*</div>
              <img className="chevron-expand-icon" alt src={Chevronexpand} />

              <div className="yrs">Yrs</div> */}

                {/* <img className="group-icon" alt src="./public/group-2757.svg" /> */}

                {/* <div className="desktop-upload-image-child1">
                <div className="rectangle-wrapper">
                  <div className="group-item">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          id="age"
                          name="age"
                          value={state.age}
                          onChange={handleInput}
                          placeholder=""
                          className="form-control-lg form-control"
                        />

                        <span className="error">{errors?.age} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="age">Age*</div> */}

                {/* <div className="desktop-upload-image-inner1">
                <div className="rectangle-wrapper">
                  <div className="group-item">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={state.name}
                          onChange={handleInput}
                          placeholder=""
                          className="form-control-lg form-control"
                        />

                        <span className="error">{errors?.name} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desktop-upload-image-child2" />
              <div className="name">Name *</div>
              <img className="biimage-alt-icon" alt src={Group} /> */}
                {/* {!cxrImageUrl && (
                <div className="button">
                  <button className="button1" onClick={handleButtonClick}>
                    Choose file
                  </button>
                  <input
                    type="file"
                    name="image"
                    className="icon-button1"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                  />
                </div>
              )} */}
                {/* {cxrImageUrl && (
                <>
                  <img class="image-14-icon" alt="" src={cxrImageUrl} />

                  <img
                    class="claritysuccess-standard-solid-icon"
                    alt=""
                    src={ClaritySuccess}
                    onClick={handleSelectCxrImage}
                  />

                  <img
                    class="si-glyphcircle-error-icon"
                    alt=""
                    src={CircleError}
                    onClick={handleRemoveCxrImage}
                  />
                </>
              )} */}
              </div>
              {/* </PreviewCard> */}
            </Block>
          </div>
        </Content>
      )}
      <Modal isOpen={editModal} className="popup-container new-form-item-form">
        {/* <ModalHeader
          close={
            <button
              className="close"
              onClick={() => {
                handleClose();
              }}
            >
              <Icon name="cross" />
            </button>
          }
        ></ModalHeader> */}

        <div className="card-inner popup-custom">
          <BlockHeadContent>
            <BlockHead size="lg">
              <div className="is-alter">
                <div className="form-group">
                  <div className="form-control-wrap edit-report-form">
                    <MultiSelectDropdown
                      options={options}
                      labelKey="name"
                      onSelect={handleSelect}
                      ref={childRef}
                    />
                    {selectedOptions?.length > 0 && (
                      <ul>
                        {/* <span>Selected Values:</span> */}
                        {selectedOptions.map((findingsval, findingsindex) => {
                          return (
                            <li key={findingsindex + 1}>
                              {findingsval.name}{" "}
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="close-custom"
                                onClick={() => {
                                  handleChildFunc(findingsval);
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="edit-repo-customform">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      {/* <div className="form-save-div"> */}
                      <button className="cancel-btn" onClick={handleClose}>
                        Cancel
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-control-wrap">
                      {/* <div className="form-save-div"> */}
                      <button className="" onClick={handleEditReportData}>
                        Save
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </BlockHead>
          </BlockHeadContent>
        </div>
        {/* <ModalFooter className=""></ModalFooter> */}
      </Modal>

      <Modal
        isOpen={sharePopup}
        className="popup-container share-container-pop"
      >
        <ModalHeader
          close={
            <button
              className="close"
              onClick={() => {
                setSharePopup(false);
              }}
            >
              <Icon name="cross" />
            </button>
          }
        ></ModalHeader>

        <div className="card-inner popup-custom">
          <BlockHeadContent>
            <BlockHead size="lg">
              <div className="is-alter">
                <div className="social-web-icons">
                  <div className="social-heading">Share via :</div>
                  <div className="social-icons">
                    <img
                      src={WatsappImg}
                      onClick={shareLink}
                      width={60}
                      height={60}
                    />
                    <img
                      src={mailIcon}
                      onClick={handleShareEmail}
                      width={60}
                      height={60}
                    />
                    <img
                      src={CopyLinkImg}
                      onClick={copyLink}
                      width={60}
                      height={60}
                    />
                  </div>
                </div>

                <div className="copy-custon">
                  <h3>or copy link</h3>
                </div>

                <div className="copy-address-custom">
                  <div className="add-copyItem">{cxrData?.download_url}</div>
                  <div className="coly-btn">
                    <img
                      src={copyUrl}
                      onClick={copyLink}
                      width={36}
                      height={36}
                    />
                  </div>
                </div>
              </div>
            </BlockHead>
          </BlockHeadContent>
        </div>
        {/* <ModalFooter className=""></ModalFooter> */}
      </Modal>
    </>
  );
};

export default EditReport;
