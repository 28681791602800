import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ role, children }) {
  const auth = sessionStorage.getItem("auth-token");

  if (!auth) {
    return <Navigate to={`${process.env.PUBLIC_URL}/auth-login`} />;
  }

  return children;
}
export default PrivateRoute;
