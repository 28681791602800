import React from "react";
import LoaderImg from "../../images/loaderimg.png";

const LoadingScreen = () => {
  return (
    <>
      <div className="loader-screen">
        <div className="loader-img">
          <img class="group-icon" alt="" src={LoaderImg} />
        </div>

        <div className="loading-text">Loading...</div>
      </div>
    </>
  );
};

export default LoadingScreen;
