import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Progress,
  DropdownItem,
  Badge,
  Input,
  Dropdown,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { projectData } from "../projects/ProjectData";
import {
  findUpper,
  setDeadline,
  setDeadlineDays,
  calcPercentage,
} from "../../../utils/Utils";
import FormModal from "../projects/FormModal";
import { ReactDataTable } from "../../../components/Component";
import EditIcon from "../../../images/Edit.png";
import PersonPlus from "../../../images/person-plus.png";
import {
  Spinner,
  Modal,
  ModalFooter,
  ModalHeader,
  Card,
  Form,
} from "reactstrap";
import { URLS } from "../../../constants/urls";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import LoadingScreen from "../../loader/LoadingScreen";
import ReactPaginate from "react-paginate";
import CustomPagination from "../CustomPagination";
import Tooltip from "../common/Tooltip";

export const UsersList = () => {
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [sm, updateSm] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPassEditModal, setShowPassEditModal] = useState(false);

  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addUserState, setAddUserState] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [clientAdminEmail, setClientAdminEmail] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [clientAdminUserId, setClientAdminUserId] = useState("");
  let [count, setCount] = useState();
  const [limitvalue, setlimitvalue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [showPassResetModal, setShowPassResetModal] = useState(false);
  const [resetPassState, setResetPassState] = useState({
    userid: "",
    email: "",
  });
  let Emailvalidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let usersData = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : "";

  const columns1 = [
    // {
    //   name: "#",
    //   selector: (row) => row.id,
    //   grow: 0,
    //   minWidth: "70px",
    //   style: { minWidth: "70px" },
    //   borderRadius: "4px",
    //   cell: (row, index) => (
    //     <>
    //       <div className="user-card mt-2 mb-2">
    //         {" "}
    //         {currentPage == 1
    //           ? index + 1
    //           : (currentPage - 1) *
    //               (limitvalue != "" ? parseInt(limitvalue) : 10) +
    //             (index + 1)}{" "}
    //       </div>
    //     </>
    //   ),
    // },

    {
      name: "Company Name",
      selector: (row) => row.company_name,
      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card new-custom-user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row.company_name}</span>
          </div>
        </div>
      ),
    },
    {
      name: "User Name",
      selector: (row) => row.email,

      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card new-custom-user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row.email}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Type",
      selector: (row) => row.user_type_name,
      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row.user_type_name}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,

      grow: 2,

      borderRadius: "4px",
      sortable: false,
      cell: (row, index) => (
        <div className="user-card active-custom-user-card mt-2 mb-2">
          {/* <div className="user-info mobile-custom">
            <span className="tb-lead ">
              {row.status == 1 ? "Active" : "Inactive"}
            </span>
          </div> */}

          <div
            className="custom-control custom-switch"
            onClick={() => {
              handleStatus(index, row.id, row.status);
            }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              checked={row.status == 1 ? true : false}
              id={row.id}
            />
            <label className="custom-control-label" htmlFor="customSwitch2">
              {" "}
            </label>
          </div>
        </div>
      ),
    },
    {
      name: "Password Reset",
      selector: (row) => row.id,
      cell: (row) => (
        <div className="user-card small-btn mt-2 mb-2">
          <Tooltip text="Password Reset">
            {/* <Link to={`/editCustomer-page/${row.id}`}> */}{" "}
            <button
              className="dropdown-item btn btn-md"
              onClick={() => {
                ResetPassConfirmationModal(row.id, row.email);
              }}
            >
              <img src={EditIcon} width={15} height={15} />
              {/* <em class="icon ni ni-edit"></em> */}
            </button>
            {/* </Link> */}
          </Tooltip>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "User Name",
      selector: (row) => row.email,

      grow: 1,

      borderRadius: "4px",
      sortable: false,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row.email}</span>
          </div>
        </div>
      ),
    },

    {
      name: "User Status",
      selector: (row) => row.status,

      grow: 1,

      borderRadius: "4px",
      sortable: false,
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2">
          {/* <div className="user-info">
            <span className="tb-lead mobile-custom">
              {row.status == 1 ? "Active" : "Inactive"}
            </span>
          </div> */}
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
          <div
            className="custom-control custom-switch"
            onClick={() => {
              handleStatus(index, row.id, row.status);
            }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              checked={row.status == 1 ? true : false}
              id={row.id}
            />
            <label className="custom-control-label" htmlFor="customSwitch2">
              {" "}
            </label>
          </div>
        </div>
      ),
    },
    {
      name: "Password Reset",
      selector: (row) => row.id,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Tooltip text="Password Reset">
            {/* <Link to={`/editCustomer-page/${row.id}`}> */}{" "}
            <button
              className="dropdown-item btn btn-md"
              onClick={() => {
                ResetPassConfirmationModal(row.id, row.email);
              }}
            >
              <img src={EditIcon} width={15} height={15} />
              {/* <em class="icon ni ni-edit"></em> */}
            </button>
            {/* </Link> */}
          </Tooltip>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        backgroundColor: "#08223C", // Change background color
      },
    },
  };

  const getUsersList = () => {
    if (currentPage == 1) {
      setLoading(true);
    }
    let data = {};
    // data.current_page = currentPage;
    data.per_page = limitvalue ? limitvalue : 10;
    if (usersData?.user_type == 1 && clientAdminEmail) {
      data.user_type = 2;
      data.email = clientAdminEmail;
    }

    let url = URLS.USERS_LIST + "?page=" + currentPage;

    makePostRequest(url, null, data, null).then((res) => {
      if (res.status) {
        setLoading(false);
        setUsersList(res?.users?.data);

        setCount(
          Math.ceil(res?.users?.total / parseInt(limitvalue ? limitvalue : 10))
        );
        if (clientAdminEmail) {
          setClientAdminUserId(res?.users?.data[0]?.id);
        }
      } else {
        setLoading(false);
      }
      //console.log("users list::", res);
    });
  };

  useEffect(() => {
    getUsersList();
  }, [clientAdminEmail, limitvalue, currentPage]);

  const handleStatus = (index, id, status) => {
    let userData = [...usersList];
    userData[index].status = status == 0 ? 1 : 0;
    setUsersList(userData);
    let data = {
      is_active: status == 0 ? 1 : 0,
      user_id: id,
    };
    setLoading(true);
    makePostRequest(URLS.USERS_ACTIVE_INACTIVE, null, data, null)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddUser = () => {
    let valid = 0;
    let errors = {};
    if (!addUserState.name) {
      errors.name = "Please enter name";
      valid++;
    }
    if (!addUserState.email) {
      errors.email = "Please enter email";
      valid++;
    }
    if (addUserState.email && !Emailvalidation.test(addUserState.email)) {
      errors.email = "The email address you entered is not valid";
      valid++;
    }

    setValidationErrors({ ...validationErrors, errors: errors });
    if (valid == 0) {
      let data = {};
      data.email = addUserState.email;
      data.name = addUserState.name;
      if (usersData?.user_type == 1) {
        data.user_id = clientAdminUserId;
      }

      makePostRequest(URLS.CREATE_USERS, null, data, null)
        .then((res) => {
          if (res.status) {
            toast.success(res.message);
            setShowAddModal(false);
            setAddUserState({});
            setUserPass(res?.password);
            setUserEmail(res?.user?.email);
            // handlePasswordReset(res?.user?.id, res?.user?.email);
            getUsersList();
          } else {
            let errors = {};
            errors.email = res?.errors?.email?.[0];
            setValidationErrors({ ...validationErrors, errors: errors });

            toast.error(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddUserState({ ...addUserState, [name]: value });
  };

  const handlePasswordReset = (userid, email) => {
    setShowPassResetModal(false);
    let data = {
      user_id: userid,
    };
    makePostRequest(URLS.RESET_PASSWORD, null, data, null)
      .then((res) => {
        if (res.status) {
          toast.success(res.message);
          setUserPass(res.password);
          setUserEmail(email);

          console.log(res);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userEmail && userPass) {
      setShowPassEditModal(true);
    }
  }, [userEmail, userPass]);

  const handleCopyClick = () => {
    const combinedData = `${userEmail}\n${userPass}`;

    const textArea = document.createElement("textarea");
    textArea.value = combinedData;
    document.body.appendChild(textArea);

    textArea.select();

    document.execCommand("copy");

    document.body.removeChild(textArea);

    toast.success("Data copied to clipboard!");
    setShowPassEditModal(false);
  };

  const handleInputChange = (e) => {
    let predefinedSuggestions = [];
    usersList?.length > 0 &&
      usersList.forEach((val) => {
        predefinedSuggestions.push(val.email);
      });
    const value = e.target.value;
    setInputValue(value);

    // Filter suggestions based on the input value (similar to the previous example)
    const filteredSuggestions = predefinedSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setClientAdminEmail(suggestion);
    setSuggestions([]);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!inputValue) {
      setSuggestions([]);
      setClientAdminEmail("");
      setClientAdminUserId("");
    }
  }, [!inputValue]);

  // const handlePageChange = (selectedPage) => {
  //   setCurrentPage(selectedPage.selected + 1);
  // };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= count) {
      setCurrentPage(pageNumber);
    }
  };

  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };

  // console.log("usersList::", usersList);
  let { errors } = validationErrors;

  // console.log("currentPage=>", currentPage);

  const [scroll, setScroll] = useState(false);
  const [scrollPagination, setScrollPagination] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY >= 0);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPagination(window.scrollY > 80);
    });
  }, []);

  const handleAddUserModalClose = () => {
    setShowAddModal(false);
    setValidationErrors({});
  };

  const ResetPassConfirmationModal = (id, email) => {
    setShowPassResetModal(true);
    setResetPassState({ ...resetPassState, userid: id, email: email });
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    enableScroll();
  }, []);

  return (
    <>
      <Head title="Users List"></Head>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="search-type-custom">
              {/* <BlockHeadContent>
              <BlockTitle page> Users</BlockTitle>
              <BlockDes className="text-soft">
                You have total {customerListData.length} users
              </BlockDes>
            </BlockHeadContent> */}
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  {/* <Button
                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                      sm ? "active" : ""
                    }`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button> */}
                  <div
                    className="toggle-expand-content"
                    style={{ display: sm ? "block" : "block" }}
                  >
                    <ul className="nk-block-tools g-3">
                      {/* <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <Icon
                            name="filter-alt"
                            className="d-none d-sm-inline"
                          ></Icon>
                          <span>Filtered By</span>
                          <Icon name="chevron-right" className="dd-indc"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Open</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Closed</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Onhold</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li> */}
                      {(usersData?.user_type == 2 ||
                        (clientAdminUserId && usersData?.user_type == 1)) && (
                        <li
                          className="nk-block-tools-opt"
                          onClick={() => setShowAddModal(true)}
                        >
                          <Button color="secondary" className="button-color">
                            {/* <Icon name="plus"></Icon> */}
                            <span>Add User</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={PersonPlus} width={15} height={15} />
                          </Button>
                        </li>
                      )}
                      {usersData?.user_type == 1 && (
                        <li>
                          <div>
                            <Input
                              type="text"
                              placeholder="Type an email address"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            {suggestions.length > 0 && (
                              <ListGroup>
                                {suggestions.map((suggestion, index) => (
                                  <ListGroupItem
                                    key={index}
                                    onClick={() =>
                                      handleSuggestionClick(suggestion)
                                    }
                                  >
                                    {suggestion}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            )}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <div className={` outerBox ${scroll ? "bg-blackss" : "bg-whites"}`}>
              {usersList?.length > 0 ? (
                <ReactDataTable
                  data={usersList}
                  columns={usersData.user_type == 1 ? columns1 : columns2}
                  className="nk-tb-list"
                  searchable={false}
                  customStyles={customStyles}
                  // setPageCount={setPageCount}
                />
              ) : (
                ""
              )}
            </div>
          </Block>
        </Content>
      )}

      {usersList?.length > 0 ? (
        <div className="pagination-custom-container">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              {/* <span className="d-none d-sm-inline-block">Show</span> */}
              <div className="form-control-select">
                {" "}
                <select
                  name="DataTables_Table_0_length"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  onChange={HandleLimitValue}
                  value={limitvalue}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>{" "}
              </div>
            </label>
          </div>
          {/* <ReactPaginate
                    previousLabel={"<-"}
                    nextLabel={"->"}
                    pageCount={count}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  /> */}

          <CustomPagination
            currentPage={currentPage}
            totalPages={count}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <div>No Data Found</div>
      )}
      <Modal isOpen={showAddModal} className="popup-container">
        <ModalHeader
          close={
            <button
              className="close"
              onClick={() => {
                handleAddUserModalClose();
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          Add User
        </ModalHeader>
        {/* <Card className="card-bordered"> */}
        <div className="card-inner popup-custom">
          <BlockHeadContent>
            <BlockHead size="lg">
              <div className="is-alter">
                {/* {usersData?.user_type == 1 && (
                  <div className="form-group">
                    <label className="form-label">Client Admin Email: </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        placeholder=""
                        className="form-control-lg form-control"
                        value={addUserState.admin_email}
                        name="email"
                        onChange={handleInput}
                      />
                      <span className="error"> {errors?.admin_email}</span>
                    </div>
                  </div>
                )} */}

                <div className="form-group">
                  <div className="form-control-wrap">
                    <label className="form-label">Name: </label>
                    <div className="add-user--sec">
                      <input
                        type="text"
                        placeholder="user"
                        className="form-control-lg form-control"
                        value={addUserState.name}
                        name="name"
                        onChange={handleInput}
                      />
                      <span className="error"> {errors?.name}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <label className="form-label">Username: </label>
                    <div className="add-user--sec">
                      <input
                        type="text"
                        placeholder="user1@xyz.com"
                        className="form-control-lg form-control"
                        value={addUserState.email}
                        name="email"
                        onChange={handleInput}
                      />
                      <span className="error"> {errors?.email}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <div className="form-save-div">
                      <button className="save-button11" onClick={handleAddUser}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </BlockHead>
          </BlockHeadContent>
        </div>
        {/* </Card> */}
      </Modal>
      <Modal isOpen={showPassEditModal} className="popup-container">
        <ModalHeader
          close={
            <button
              className="close"
              onClick={() => {
                setShowPassEditModal(false);
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          User Credentials
        </ModalHeader>
        {/* <Card className="card-bordered"> */}
        <div className="card-inner popup-custom">
          <BlockHeadContent>
            <BlockHead size="lg">
              <div className="is-alter">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <label className="form-label">Username : </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control-lg form-control"
                      value={userEmail}
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <label className="form-label">Password : </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control-lg form-control"
                      value={userPass}
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <div className="form-save-div">
                      <button
                        className="save-button11"
                        onClick={handleCopyClick}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </BlockHead>
          </BlockHeadContent>
        </div>
        {/* </Card> */}
      </Modal>

      <Modal
        isOpen={showPassResetModal}
        className="popup-container custom password-popup"
      >
        <ModalHeader
          close={
            <button
              className="close"
              onClick={() => {
                setShowPassResetModal(false);
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          Are you sure you want to reset password ?
        </ModalHeader>

        <div className="card-inner popup-custom sure-popup">
          <BlockHeadContent>
            <BlockHead size="lg">
              <div className="is-alter">
                <div className="clear-apply-container">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <div className="form-save-div">
                        <button
                          className="save-button11 bg"
                          onClick={() => {
                            setShowPassResetModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <div className="form-save-div">
                        <button
                          className="save-button11 new-width"
                          onClick={() => {
                            handlePasswordReset(
                              resetPassState.userid,
                              resetPassState.email
                            );
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BlockHead>
          </BlockHeadContent>
        </div>
      </Modal>
    </>
  );
};

export default UsersList;
