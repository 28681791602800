import React, { useState, useEffect } from "react";
// import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../components/Component";
import { Link } from "react-router-dom";
import Logo from "../../images/image-8@2x.png";
import { URLS } from "../../constants/urls";
import { makePostRequest, makeGetRequest } from "../../service/api";
import { ToastContainer, toast } from "react-toastify";

const TermsConditions = () => {
  const [checkTerm, setCheckTerm] = useState(false);
  const [termsConditionsContent, setTermsConditionContent] = useState("");

  const handleInput = (e) => {
    if (e.target.checked) {
      setCheckTerm(true);
    } else {
      setCheckTerm(false);
    }
  };

  const getTermsConditions = () => {
    makeGetRequest(URLS.TERMS_CONDITIONS, null, null, null).then((res) => {
      setTermsConditionContent(res);

      //console.log("terms conditions::", res);
    });
  };

  useEffect(() => {
    getTermsConditions();
  }, []);

  const handleTermsConditionsAccept = () => {
    let data = {};

    makePostRequest(URLS.TERMS_CONDITIONS_ACCEPT, null, data, null)
      .then((res) => {
        if (res.status) {
          toast.success(res.message);
          setTimeout(() => {
            window.history.pushState(
              `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
              "auth-login",
              `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
            );
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* <Head title="Terms &amp; Conditions" /> */}
      <Content>
        <div className="content-page wide-md m-auto terms-custom">
          <Block>
            <div className="brand-logo pb-4 text-center">
              <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img
                  className="logo-light logo-img logo-img-lg"
                  src={Logo}
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img logo-img-lg"
                  src={Logo}
                  alt="logo-dark"
                />
              </Link>
            </div>
            <PreviewCard className="card-bordered card-style">
              {/* <div className="tc">
                <div className="vector-parent"> */}
              {/* <div className="terms-conditions">Terms &amp; conditions</div>
                  <div className="privacy-policy">Privacy Policy</div> */}
              {/* <div className="aliquet-neque-tincidunt-container">
                    <p className="aliquet-neque-tincidunt">
                      Aliquet neque, tincidunt et et congue sed fringilla eu
                      nascetur. Sed posuere nisl, et proin turpis neque
                      facilisis quisque blandit. Habitant arcu lectus non
                      convallis sagittis. Id volutpat feugiat a, diam proin
                      augue id pretium aliquam. Enim pretium venenatis, lacus,
                      urna at mattis facilisis elementum. Scelerisque nec at sit
                      massa. Tristique eu elementum enim nascetur. Id magna et
                      viverra cursus enim.
                    </p>
                    <p className="aliquet-neque-tincidunt">
                      Aliquet neque, tincidunt et et congue sed fringilla eu
                      nascetur. Sed posuere nisl, et proin turpis neque
                      facilisis quisque blandit. Habitant arcu lectus non
                      convallis sagittis. Id volutpat feugiat a, diam proin
                      augue id pretium aliquam. Enim pretium venenatis, lacus,
                      urna at mattis facilisis elementum. Scelerisque nec at sit
                      massa. Tristique eu elementum enim nascetur. Id magna et
                      viverra cursus enim.
                    </p>
                  </div>
                  <div className="aliquet-neque-tincidunt-container1">
                    <p className="aliquet-neque-tincidunt">
                      Aliquet neque, tincidunt et et congue sed fringilla eu
                      nascetur. Sed posuere nisl, et proin turpis neque
                      facilisis quisque blandit. Habitant arcu lectus non
                      convallis sagittis. Id volutpat feugiat a, diam proin
                      augue id pretium aliquam. Enim pretium venenatis, lacus,
                      urna at mattis facilisis elementum. Scelerisque nec at sit
                      massa. Tristique eu elementum enim nascetur. Id magna et
                      viverra cursus enim.
                    </p>
                    <p className="aliquet-neque-tincidunt">
                      Aliquet neque, tincidunt et et congue sed fringilla eu
                      nascetur. Sed posuere nisl, et proin turpis neque
                      facilisis quisque blandit. Habitant arcu lectus non
                      convallis sagittis. Id volutpat feugiat a, diam proin
                      augue id pretium aliquam. Enim pretium venenatis, lacus,
                      urna at mattis facilisis elementum. Scelerisque nec at sit
                      massa. Tristique eu elementum enim nascetur. Id magna et
                      viverra cursus enim.
                    </p>
                    <p className="aliquet-neque-tincidunt">
                      Aliquet neque, tincidunt et et congue sed fringilla eu
                      nascetur. Sed posuere nisl, et proin turpis neque
                      facilisis quisque blandit. Habitant arcu lectus non
                      convallis sagittis. Id volutpat feugiat a, diam proin
                      augue id pretium aliquam. Enim pretium venenatis, lacus,
                      urna at mattis facilisis elementum. Scelerisque nec at sit
                      massa. Tristique eu elementum enim nascetur. Id magna et
                      viverra cursus enim.
                    </p>
                  </div> */}

              <div
                dangerouslySetInnerHTML={{
                  __html: termsConditionsContent,
                }}
              />
            </PreviewCard>
              {/* <div className="frame-inner" /> */}

              {/* </div> */}

              {/* </div> */}

              {/* <div className="vector-group"> */}
              <div className="form-check form-check-inline">
                <div className="check-box-custom">
                  <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineRadio555"
                  name="checkTerm"
                  value={checkTerm}
                  onChange={handleInput}
                />
                {/* <label
                  className="form-check-label  handle-radio"
                  htmlfor="inlineRadio555"
                /> */}
                </div>
                <div className="i-agree-to">
                  I agree to the Zeka’s terms &amp; conditions and privacy
                  policy
                </div>
              </div>
              <div className="i-agree-to-the-zekas-terms-wrapper">
                <button
                className="group-child"
                disabled={checkTerm == true ? false : true}
                onClick={handleTermsConditionsAccept}
              >
                {/* <div className="get-started">Get Started!</div> */}
                <div className="">Get Started!</div>
              </button>
              </div>
              {/* </div> */}
              {/* <div className="rectangle-parent"> */}
              
              {/* </div> */}
            
          </Block>
        </div>
      </Content>
    </>
  );
};
export default TermsConditions;
