// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.multi-select-dropdown {
    position: relative;
    width: 200px;
    font-family: Arial, sans-serif;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .dropdown-header {
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
  }
  
  .dropdown-content {
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
  }
  
  .dropdown-option {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-option.selected {
    background-color: #e0f0ff;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/pre-built/common/MultiSelectDropdown.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,8BAA8B;IAC9B,yBAAiB;YAAjB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,sBAAsB;IACtB,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["\n.multi-select-dropdown {\n    position: relative;\n    width: 200px;\n    font-family: Arial, sans-serif;\n    user-select: none;\n  }\n  \n  .dropdown-header {\n    padding: 10px;\n    border: 1px solid #ccc;\n    cursor: pointer;\n    background-color: #f9f9f9;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .dropdown-content {\n    max-height: 200px;\n    overflow-y: auto;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    border: 1px solid #ccc;\n    border-top: none;\n    background-color: #fff;\n  }\n  \n  .dropdown-option {\n    padding: 5px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n  \n  .dropdown-option.selected {\n    background-color: #e0f0ff;\n  }\n  \n  input[type=\"text\"] {\n    width: 100%;\n    padding: 5px;\n    margin-bottom: 5px;\n    border: 1px solid #ccc;\n  }\n  \n  button {\n    margin-top: 10px;\n    padding: 5px 10px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
