import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import News from "../news/News";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";

import { useTheme, useThemeUpdate } from "../provider/Theme";

const Header = ({ fixed, className, ...props }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]:
      theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  return (
    <div className={headerClass}>
      <div className="container-fluid custom-header">
        <div className="nk-header-wrap ">
          <ul class="child">
            <li className="nk-menu-trigger  ms-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon ms-n1"
                icon="menu"
                click={themeUpdate.sidebarVisibility}
              />
            </li>
            <li className="nk-header-brand ">
              <Logo />
            </li>
            {/* <li className="nk-header-news  d-xl-block">
            <News />
          </li> */}

            <li className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="user-dropdown" onClick={themeUpdate.sidebarHide}>
                  <User />
                </li>
                {/* <li
                className="notification-dropdown me-n1"
                onClick={themeUpdate.sidebarHide}
              >
                <Notification />
              </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Header;
