import React, { useState } from "react";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import Logo from "../../images/image-8@2x.png";
import LoginArrow from "../../images/login_arrow.png";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { URLS } from "../../constants/urls";
import { makePostRequest } from "../../service/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../loader/LoadingScreen";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passicon, setpassicon] = useState(false);
  const [errorVal, setError] = useState("");
  const [usernameErr, setUserNameErr] = useState("");
  const [passErr, setPassErr] = useState("");

  const navigate = useNavigate();

  let Emailvalidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "username") {
      setUserName(value);
    }
    if (name == "password") {
      setPassword(value);
    }
  };

  const onFormSubmit = () => {
    if (!username) {
      setUserNameErr("Please enter username");
      return;
    }
    if (!password) {
      setPassErr("Please enter password");
      return;
    }
    if (username && !Emailvalidation.test(username)) {
      setUserNameErr("The email address you entered is not valid");
      return;
    }

    setLoading(true);

    let data = {
      email: username,
      password: password,
    };
    makePostRequest(URLS.LOGIN, null, data, null)
      .then((response) => {
        setLoading(false);
        console.log(response);
        if (response.status) {
          sessionStorage.setItem("auth-token", response.token);
          sessionStorage.setItem("menu", JSON.stringify(response.menus));
          sessionStorage.setItem("user", JSON.stringify(response.user));
          toast.success(response.message);
          if (
            !response?.user?.email_verified_at &&
            response?.user?.user_type == 3
          ) {
            navigate("/auth-reset");
          } else if (response?.user?.is_terms_acc == 0) {
            navigate("/terms-conditions");
          } else {
            setTimeout(() => {
              window.history.pushState(
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
                "auth-login",
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
              );
              window.location.reload();
            }, 1000);
          }
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Head title="Login" />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          {/* <ToastContainer /> */}
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={Logo}
                alt="logo-dark"
              />
            </Link>
          </div>

          <div className="is-alter form-custom">
            <div className="form-group">
              {/* <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Email or Mobile no.
                </label>
              </div> */}
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  value={username}
                  name="username"
                  onChange={handleInput}
                  placeholder="Email"
                  className="form-control-lg form-control"
                />

                <span className="error"> {usernameErr}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="form-control-wrap font-family-pass">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setpassicon(!passicon);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    passicon ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon
                    name="eye-off"
                    className="passcode-icon icon-hide"
                  ></Icon>
                </a>

                <input
                  type={passicon == false ? "password" : "text"}
                  id="password"
                  placeholder="Password"
                  value={password}
                  name="password"
                  onChange={handleInput}
                  className={`form-control-lg  form-control`}
                />

                <span className="error"> {passErr}</span>

                {/* <Link
                  className="link link-primary link-sm"
                  to={`${process.env.PUBLIC_URL}/auth-reset`}
                >
                  Reset password
                </Link> */}
              </div>
            </div>
            <div className="form-group loginItem-custom">
              <Button
                size="lg"
                className="btn-block"
                type="submit"
                color=""
                onClick={onFormSubmit}
              >
                {loading ? <Spinner size="sm" color="light" /> : "LOG IN"}
              </Button>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="btn btn-lg login_arrow"
                onClick={onFormSubmit}
              />
            </div>
          </div>
          <div className="form-note-s2 text-center pt-4">
            {/* New on our platform?{" "} */}
            <Link to={`${process.env.PUBLIC_URL}/auth-register`}>
              Create an account
            </Link>
          </div>
        </Block>
      )}

      {/* <AuthFooter /> */}
    </>
  );
};
export default Login;
