export const URLS = {
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  USERS_LIST: "/users/get-users",
  USERS_ACTIVE_INACTIVE: "/users/enable-disable",
  CREATE_USERS: "/users/create-user",
  TERMS_CONDITIONS: "/terms-condition",
  TERMS_CONDITIONS_ACCEPT: "/terms-condition/accept",
  RESET_PASSWORD: "/users/reset-password",
  UPDATE_PASSWORD: "/users/update-password",
  CXR_FORM: "/cxr",
  BONE_FORM: "/bone",
  CXR_REPORT: "/cxr/get-report",
  CXR_EDIT_REPORT: "/cxr/edit-report",
  GET_DISEASE: "/cxr/get-disease",
};
