import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import Logo from "../../images/image-8@2x.png";
import ModalIcon from "../../images/modal-image.svg";
import LoginArrow from "../../images/login_arrow.png";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  BlockHeadContent,
} from "../../components/Component";
import { Spinner, Modal, ModalFooter, ModalHeader, Card } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { URLS } from "../../constants/urls";
import { makePostRequest } from "../../service/api";
import { ToastContainer, toast } from "react-toastify";
import LoadingScreen from "../loader/LoadingScreen";
import { validatePassword, validateMobileNumber } from "../../utils/Utils";

const Register = () => {
  const [passState, setPassState] = useState(false);
  const [passState1, setPassState1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [state, setState] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  let Emailvalidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const navigate = useNavigate();
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // setLoading(true);
    let valid = 0;
    let errors = {};
    if (!state.companyName) {
      errors.companyName = "Please enter comapny name";
      valid++;
    }

    if (!state.email) {
      errors.email = "Please enter email address";
      valid++;
    }

    if (!state.mobile) {
      errors.mobile = "Please enter mobile no.";
      valid++;
    }

    if (!state.password) {
      errors.password = "Please enter password";
      valid++;
    }
    if (!state.confirmpassword) {
      errors.confirmpassword = "Please re-enter password";
      valid++;
    }

    if (state.mobile) {
      if (isNaN(state.mobile)) {
        errors.mobile = "Please enter number";
        valid++;
      } else if (state.mobile < 0) {
        errors.mobile = "Negative number not allowed";
        valid++;
      } else if (validateMobileNumber(state.mobile) == false) {
        errors.mobile = "Mobile number must have 10 digits.";
        valid++;
      }
    }

    if (state.email && !Emailvalidation.test(state.email)) {
      errors.email = "The email address you entered is not valid";
      valid++;
    }

    if (state.password && validatePassword(state.password) == false) {
      errors.password =
        "Password must have at least 8 characters including letters and numbers.";
      valid++;
    }

    if (
      state.password &&
      state.confirmpassword &&
      state.password != state.confirmpassword
    ) {
      errors.confirmpassword = "Password and re-enter password must match";
      valid++;
    }

    setValidationErrors({ ...validationErrors, errors: errors });

    if (valid == 0) {
      setLoading(true);
      let data = {
        name: state.companyName,
        email: state.email,
        password: state.password,
        mobile: state.mobile,
        company_name: state.companyName,
        //user_role:1
      };
      makePostRequest(URLS.REGISTER, null, data, null)
        .then((res) => {
          console.log("response::", res);
          setLoading(false);
          if (res.status) {
            // navigate("/auth-login");
            toast.success(res.message);
            setIsOpenModal(true);
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // let errors = {};
    // if (name == "password") {
    //   let res = validatePassword(newPassword);
    //   setIsValid(res);
    //   if (res) {
    //     errors.password =
    //       "Password must have at least 8 characters including letters and numbers.";
    //   } else {
    //     errors.password = "";
    //   }
    // }
    //setValidationErrors({ ...validationErrors, errors: errors });
    setState({ ...state, [name]: value });
  };

  const redirectToLogin = (e) => {
    e.preventDefault();
    setIsOpenModal(false);
    navigate("/auth-login");
  };

  let { errors } = validationErrors;
  //console.log(errors);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Modal isOpen={isOpenModal}>
            <Card className="card-bordered">
              <div className="card-inner custom-popup">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="rectangle-parent">
                      <div className="group-child">
                        <img className="modal-image-icon" alt src={ModalIcon} />
                      </div>
                      <div className="welcome-aboard-you content-custom">
                        Welcome aboard!! You will get your login credentials via
                        your registered email within 24 hours
                      </div>
                      <div className="button button-custom">
                        <Button
                          type="submit"
                          color="primary"
                          size="lg"
                          className="btn-block"
                          onClick={(e) => {
                            redirectToLogin(e);
                          }}
                        >
                          Okay
                        </Button>
                      </div>
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </Card>
          </Modal>
          {!isOpenModal && (
            <>
              <Head title="Register" />
              <Block className="nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={Logo}
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={Logo}
                      alt="logo-dark"
                    />
                  </Link>
                </div>
                {/* <PreviewCard className="card-bordered" bodyClass="card-inner-lg"> */}
                {/* <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Register</BlockTitle>
          <BlockDes>
            <p>Create New Dashlite Account</p>
          </BlockDes>
        </BlockContent>
      </BlockHead> */}
                <div className="is-alter form-custom">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="name">
            Name
          </label> */}
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={state?.companyName}
                        onChange={handleInput}
                        placeholder="Company Name"
                        className="form-control-lg form-control"
                      />
                      {/* {errors.name && <p className="invalid">This field is required</p>} */}
                      <span className="error"> {errors?.companyName}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    {/* <div className="form-label-group">
            <label className="form-label" htmlFor="default-01">
              Email or Username
            </label>
          </div> */}
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        bssize="lg"
                        id="default-01"
                        name="email"
                        value={state?.email}
                        onChange={handleInput}
                        className="form-control-lg form-control"
                        placeholder="Email ID"
                      />
                      {/* {errors.email && (
              <p className="invalid">This field is required</p>
            )} */}
                      <span className="error"> {errors?.email}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="name">
            Name
          </label> */}
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={state?.mobile}
                        onChange={handleInput}
                        placeholder="Mobile no."
                        className="form-control-lg form-control"
                      />
                      {/* {errors.mobile && (
              <p className="invalid">This field is required</p>
            )} */}
                      <span className="error"> {errors?.mobile}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    {/* <div className="form-label-group">
            <label className="form-label" htmlFor="password">
              Passcode
            </label>
          </div> */}
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-show"
                        ></Icon>

                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-hide"
                        ></Icon>
                      </a>
                      <input
                        type={passState ? "text" : "password"}
                        id="password"
                        name="password"
                        value={state?.password}
                        onChange={handleInput}
                        placeholder="Password"
                        className={`form-control-lg form-control ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      />
                      {/* {errors.passcode && (
              <span className="invalid">{errors.passcode.message}</span>
            )} */}
                      <span className="error"> {errors?.password}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    {/* <div className="form-label-group">
            <label className="form-label" htmlFor="password">
              Passcode
            </label>
          </div> */}
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState1(!passState1);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState1 ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-show"
                        ></Icon>

                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-hide"
                        ></Icon>
                      </a>
                      <input
                        type={passState1 ? "text" : "password"}
                        id="reenterpassword"
                        name="confirmpassword"
                        value={state?.confirmpassword}
                        onChange={handleInput}
                        placeholder="Re enter Password"
                        className={`form-control-lg form-control ${
                          passState1 ? "is-hidden" : "is-shown"
                        }`}
                      />
                      {/* {errors.passcode && (
              <span className="invalid">{errors.passcode.message}</span>
            )} */}
                      <span className="error"> {errors?.confirmpassword}</span>
                    </div>
                  </div>
                  <div className="form-group register-custom">
                    <Button
                      type="submit"
                      color=""
                      size="lg"
                      className="btn-block"
                      onClick={handleFormSubmit}
                    >
                      {loading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "SIGN UP"
                      )}
                    </Button>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="btn btn-lg login_arrow"  onClick={handleFormSubmit}  
                    />
                  </div>
                </div>
                {/* <div className="form-note-s2 text-center pt-4">
        {" "}
        Already have an account?{" "}
        <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
          <strong>Sign in instead</strong>
        </Link>
      </div> */}
                {/* <div className="text-center pt-4 pb-3">
        <h6 className="overline-title overline-title-sap">
          <span>OR</span>
        </h6>
      </div>
      <ul className="nav justify-center gx-8">
        <li className="nav-item">
          <a
            className="nav-link"
            href="#socials"
            onClick={(ev) => {
              ev.preventDefault();
            }}
          >
            Facebook
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="#socials"
            onClick={(ev) => {
              ev.preventDefault();
            }}
          >
            Google
          </a>
        </li>
      </ul> */}
                {/* </PreviewCard> */}
              </Block>
            </>
          )}
        </>
      )}

      {/* <AuthFooter /> */}
    </>
  );
};
export default Register;
